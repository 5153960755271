import classes from './ClinicalDiagnosis.module.css';

const Skin = (props) => {
  const data = props.value || { value: 'N/A', classification: 'N/A' };
  const index = props.index || 'N/A';

  return (
    <div className={`${classes.inline} ${classes.row} ${classes.section}`}>
      <h4 className={classes.title}>{index}. Da liễu:</h4>
      <div>{data.value}</div>
      <div>Phân loại: {data.classification}</div>
    </div>
  );
};

export default Skin;
