import classes from './NormalSupport.module.css';

const NormalSupport = (props) => {
  return (
    <div className={classes.support}>
      <p className={classes.question}>Bạn cần hỗ trợ?</p>
      <p className={classes.contact}>
        Liên hệ{' '}
        <a className={classes.phone} href="tel:+842923827179">
          0292 382 7179
        </a>{' '}
        hoặc{' '}
        <a
          className={classes.facebook}
          href="https://www.facebook.com/pkdxtpct/"
        >
          Facebook
        </a>
      </p>
    </div>
  );
};

export default NormalSupport;
