import Col from './TableCol';
import Row from './TableRow';
import classes from './ResultTable.module.css';

const ResultTable = (props) => {
  const { name, result, normal } = props || {};

  return (
    <section className={classes.table}>
      <Row className={classes.header}>
        <Col className={classes.name}>XÉT NGHIỆM</Col>
        <Col className={classes.result}>KẾT QUẢ</Col>
        <Col className={classes.normal}>TRỊ SỐ BÌNH THƯỜNG</Col>
      </Row>
      <Row>
        <Col className={classes.name}>{name}</Col>
        <Col className={classes.result}>{result}</Col>
        <Col className={classes.normal}>{normal}</Col>
      </Row>
    </section>
  );
};

export default ResultTable;
