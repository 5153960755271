import classes from './HomePage.module.css';
import logo from '../assets/images/logo.png';
const HomePage = (props) => {
  return (
    <div className={classes.home}>
      <img src={logo} alt="Logo phòng khám" />
      <h3 className={classes.title}>PHÒNG KHÁM ĐA KHOA ĐỒNG XUÂN</h3>
      <p>179 Nguyễn Văn Linh, P. An Khánh, Q. Ninh Kiều, Tp Cần Thơ</p>
      <p>Điện thoại: <a href='tel:02923827179'>(0292) 3 827 179</a></p>
      <a href="register" className={classes.colRegister}>
        <h4>ĐĂNG KÝ LỊCH XÉT NGHIỆM</h4>
      </a>
      <a href="results" className={classes.colSearch}>
        <h4>TRA CỨU KẾT QUẢ XÉT NGHIỆM</h4>
      </a>
    </div>
  );
};

export default HomePage;
