import Subclinical from './Subclinical';

const SubclinicalDiagnosis = (props) => {
  const data = props.value || [];

  return (
    <section>
      <h3>III. Khám cận lâm sàng</h3>
      {data.map((item, index) => (
        <Subclinical key={item.name} index={index + 1} value={item} />
      ))}
    </section>
  );
};

export default SubclinicalDiagnosis;
