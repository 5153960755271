import classes from './Advice.module.css';

const Advice = (props) => {
  const data = props.value || '';
  const advices = data.split('\n');

  return (
    <section className={classes.advice}>
      <h3 className={classes.title}>V. Lời dặn</h3>
      {advices.map((advice, index) => (
        <p className="advice" key={index}>
          {advice}
        </p>
      ))}
    </section>
  );
};
export default Advice;
