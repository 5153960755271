import classes from './BackgroundError.module.css';
import ErrorSupport from './ErrorSupport';
import BackgroundMessage from './BackgroundMessage';

/**
 * @typedef {{technicalMessage: String; httpCode: Number}} Error
 * @param {{error: Error}} props
 */
const BackgroundError = (props) => {
  const error = props.error;
  let friendlyMessage;

  switch (error.httpCode) {
    case 404:
      friendlyMessage =
        props.notFoundMessage ||
        'Xin lỗi, chúng tôi ko tìm thấy hồ sơ của bạn :((';
      break;
    default:
      friendlyMessage =
        props.unknownMessage || 'Úpp... Ko thể xử lý yêu cầu :((';
      break;
  }
  return (
    <BackgroundMessage className={classes.error}>
      <p className={classes.message}>
        {friendlyMessage || error.technicalMessage}
      </p>
      <ErrorSupport />
    </BackgroundMessage>
  );
};

export default BackgroundError;
