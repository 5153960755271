import { useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import CovidTestInfo from '../components/covid-test/CovidTestInfo';
import CovidUserInfo from '../components/covid-test/CovidUserInfo';
import ResultTable from '../components/covid-test/ResultTable';
import Signature from '../components/covid-test/Signature';
import BackgroundError from '../components/error/BackgroundError';
import BackgroundMessage from '../components/error/BackgroundMessage';
import Layout from '../components/layout/Layout';
import ReportHeader from '../components/ReportHeader';
import Title from '../components/ui/Title';
import useHttp from '../hooks/use-http';
import { getCovidTestResult } from '../libs/api';
import AuthContext from '../store/auth-context';
import classes from './CovidTestResultPage.module.css';

const CovidTestResultPage = () => {
  const { sendRequest, status, data, error } = useHttp(getCovidTestResult);
  const { authType, token } = useContext(AuthContext);
  const router = useRouteMatch();

  const { resultId } = router.params;

  useEffect(() => {
    sendRequest({ authType, token, resultId });
  }, [sendRequest, authType, token, resultId]);

  let content = <BackgroundMessage inlineMessage="Đang xử lý..." />;

  if (status === 'completed' && data) {
    const { user, testInfo, result } = data;

    content = (
      <div className={classes.covid}>
        <ReportHeader />
        <Title>PHIẾU XÉT NGHIỆM</Title>
        <CovidUserInfo
          name={user.name}
          gender={user.gender}
          age={user.age}
          phoneNumber={user.phoneNumber}
          address={user.address}
        />
        <CovidTestInfo
          diagnosis={testInfo.diagnosis}
          appointedDoctor={testInfo.appointedDoctor}
        />
        <ResultTable
          name={result.name}
          result={result.result}
          normal={result.normal}
        />
        <Signature date={testInfo.testDate} doctor={testInfo.testDoctor} />
      </div>
    );
  }

  if (status === 'completed' && error) {
    content = <BackgroundError error={error} />;
  }

  return <Layout>{content}</Layout>;
};

export default CovidTestResultPage;
