import { Link, useHistory } from 'react-router-dom';
import classes from './ResultItem.module.css';

const ResultItem = (props) => {
  const { id, name, date, type } = props;
  const link = `/results/${type.toLowerCase()}/${id}`;
  const history = useHistory();

  const goToDetail = () => {
    history.push(link);
  };

  return (
    <div className={classes.item} onClick={goToDetail}>
      <div className={classes.detail}>
        <div className={classes.name}>{name}</div>
        <div className={classes.date}>{date}</div>
      </div>
      <Link to={link} className={classes.view}>
        Xem
      </Link>
    </div>
  );
};

export default ResultItem;
