import classes from './SubclinicalDiagnosis.module.css';

const Subclinical = (props) => {
  const data =
    props.value ||
    {
      name: 'N/A',
      results: [],
    };
  const index = props.index || 'N/A';

  return (
    <div className={classes.section}>
      <div className={classes.header}>
        <h4 className={classes.title}>
          {index}. {data.name}
        </h4>
        <div>Giá trị bình thường</div>
        <div>Kết quả</div>
      </div>
      {data.results.map((result) => (
        <div key={result.name} className={classes.body}>
          <div>- {result.name}</div>
          <div>{result.normalResult}</div>
          <div>{result.value}</div>
        </div>
      ))}
    </div>
  );
};

export default Subclinical;
