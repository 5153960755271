import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import logo from '../../assets/images/logo.png';
import classes from './AuthForm.module.css';
import PhoneNumberForm from './PhoneNumberForm';

const AuthForm = (props) => {
  const match = useRouteMatch();

  return (
    <section className={classes.form}>
      <a href='/'><img src={logo} alt="Logo phòng khám" /></a>
      <h3 className={classes.title}>TRA CỨU KẾT QUẢ KHÁM SỨC KHỎE</h3>
      <Switch>
        <Route path={`${match.path}`} exact>
          <PhoneNumberForm />
        </Route>
        {/* <Route path={`${match.path}/phone`}>
          <PhoneNumberForm />
        </Route>
        <Route path={`${match.path}/username`}>
          <UsernameForm />
        </Route> */}
        <Route path="*" exact>
          <Redirect to={`${match.path}`} />
        </Route>
      </Switch>
    </section>
  );
};

export default AuthForm;
