import classes from './CovidUserInfo.module.css';

const CovidUserInfo = (props) => {
  const { name, gender, age, phoneNumber, address } = props;

  return (
    <section className={classes.info}>
      <div className={classes.detail}>Họ tên: {name}</div>
      <div className={classes.detail}>Giới tính: {gender}</div>
      <div className={classes.detail}>Tuổi: {age}</div>
      <div className={classes.detail}>SĐT: {phoneNumber}</div>
      <div className={classes.detail}>Địa chỉ: {address}</div>
    </section>
  );
};

export default CovidUserInfo;
