import Eyes from './Eyes';
import classes from './ClinicalDiagnosis.module.css';
import EarNoseMouth from './EarNoseMouth';
import InternalMedicine from './InternalMedicine';
import Obstetrics from './Obstetrics';
import Skin from './Skin';
import Surgery from './Surgery';
import Tooth from './Tooth';

const ClinicalDiagnosis = (props) => {
  const data = props.value || {};
  let index = 1;

  return (
    <section className={classes.clinical}>
      <h3 className={classes.title}>II. Khám lâm sàng</h3>
      <InternalMedicine index={index++} value={data.internalMedicine} />
      <Surgery index={index++} value={data.surgery} />
      {data.obstetrics.value && data.obstetrics.value !== 'N/A' && (
        <Obstetrics index={index++} value={data.obstetrics} />
      )}
      <Eyes index={index++} value={data.eyes} />
      <EarNoseMouth index={index++} value={data.earNoseMouth} />
      <Tooth index={index++} value={data.tooth} />
      <Skin index={index++} value={data.skin} />
    </section>
  );
};

export default ClinicalDiagnosis;
