import { useCallback, useReducer } from 'react';

const initState = (isPendingAtFirst = false) => ({
  status: isPendingAtFirst ? 'pending' : null,
  data: null,
  error: null,
});

const httpReducer = (state, action) => {
  if (action.type === 'SENDING') {
    return {
      status: 'pending',
      data: null,
      error: null,
    };
  }
  if (action.type === 'SENT') {
    return {
      status: 'completed',
      data: action.data,
      error: null,
    };
  }
  if (action.type === 'ERROR') {
    return {
      status: 'completed',
      data: null,
      error: action.error,
    };
  }
  return state;
};

/**
 * @param {*} requester Handler will be use to call http
 * @param {boolean} isPendingAtFirst return status pending immediately
 * @returns {{sendRequest: (requestData: any) => Promise<void>, status: String, error: any, data: any}}
 */
const useHttp = (requester, isPendingAtFirst = false) => {
  const [httpState, dispatch] = useReducer(
    httpReducer,
    initState(isPendingAtFirst)
  );

  const sendRequest = useCallback(
    async (requestData) => {
      dispatch({ type: 'SENDING' });

      try {
        const data = await requester(requestData);
        dispatch({ type: 'SENT', data });
      } catch (error) {
        dispatch({
          type: 'ERROR',
          error: {
            technicalMessage: error.message || 'Lỗi gì đó, vui lòng liên hệ phòng khám :((',
            httpCode: error.httpCode || 500,
          },
        });
      }
    },
    [requester]
  );

  return { sendRequest, ...httpState };
};

export default useHttp;
