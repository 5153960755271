import { useCallback, useReducer } from 'react';
import {
  signInWithPhoneNumber,
  confirmOtpCode,
} from '../libs/firebase-auth-api';

const initState = (isPendingAtFirst = false) => ({
  status: isPendingAtFirst ? 'pending' : null,
  signedIn: false,
  confirmedCode: false,
  token: null,
  error: null,
});

const firebaseAuthReducer = (state, action) => {
  if (action.type === 'SENDING') {
    return {
      ...state,
      status: 'pending',
      error: null,
    };
  }
  if (action.type === 'SENT') {
    return {
      ...state,
      status: 'completed',
      signedIn: action.signedIn || state.signedIn,
      confirmedCode: action.confirmedCode || state.confirmedCode,
      token: action.token || state.token,
    };
  }
  if (action.type === 'ERROR') {
    return {
      ...state,
      status: 'completed',
      error: action.error,
    };
  }
  return state;
};

const useFirebaseAuth = (isPendingAtFirst = false) => {
  const [state, dispatch] = useReducer(
    firebaseAuthReducer,
    initState(isPendingAtFirst)
  );

  const sendRequest = useCallback(
    async (requestData) => {
      try {
        let data;
        dispatch({ type: 'SENDING' });
        if (!state.signedIn) {
          data = await signInWithPhoneNumber(requestData);
        } else {
          data = await confirmOtpCode(requestData);
        }
        dispatch({ type: 'SENT', ...data });
      } catch (error) {
        console.log(error);
        const errorData = {
          errorCode: error.code,
          technicalMessage:
            error.message | 'Lỗi gì đó, vui lòng liên hệ phòng khám :((',
        };
        dispatch({
          type: 'ERROR',
          error: errorData,
        });
      }
    },
    [state.signedIn]
  );

  return { sendRequest, ...state };
};

export default useFirebaseAuth;
