import { useContext, useEffect } from 'react';
import BackgroundError from '../components/error/BackgroundError';
import BackgroundMessage from '../components/error/BackgroundMessage';
import Layout from '../components/layout/Layout';
import ResultItem from '../components/results/ResultItem';
import Title from '../components/ui/Title';
import useHttp from '../hooks/use-http';
import { getResults } from '../libs/api';
import AuthContext from '../store/auth-context';
import classes from './ResultListPage.module.css';

const ResultListPage = (props) => {
  const { sendRequest, status, data, error } = useHttp(getResults);
  const { authType, token } = useContext(AuthContext);

  useEffect(() => {
    sendRequest({ authType, token });
  }, [sendRequest, authType, token]);

  let content = <BackgroundMessage inlineMessage="Đang xử lý..." />;

  if (status === 'completed' && data && data.length > 0) {
    content = (
      <div className={classes.results}>
        <Title>DANH SÁCH KẾT QUẢ</Title>
        {data.map((item) => (
          <ResultItem
            key={item.id}
            id={item.id}
            name={item.name}
            type={item.type}
            date={item.date}
          />
        ))}
      </div>
    );
  } else if (status === 'completed' && data && data.length === 0) {
    content = <BackgroundMessage inlineMessage="Bạn chưa có hồ sơ khám nào." />;
  }

  if (status === 'completed' && error) {
    content = <BackgroundError error={error} />;
  }

  return <Layout>{content}</Layout>;
};

export default ResultListPage;
