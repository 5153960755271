import firebase from 'firebase/app';
import 'firebase/auth';

let confirmationResult;
let recaptchaVerifier;

export const signInWithPhoneNumber = async (phoneNumber) => {
  confirmationResult = await firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, recaptchaVerifier);
  return {
    signedIn: true,
  };
};

export const confirmOtpCode = async (otpCode) => {
  const result = await confirmationResult.confirm(otpCode);
  const token = await result.user.getIdToken();
  return {
    confirmedCode: true,
    token: token,
  };
};

export const registerRecaptchaVerifier = () => {
  firebase.auth().languageCode = 'vi';
  recaptchaVerifier = new firebase.auth.RecaptchaVerifier('otpCodeBtn', {
    size: 'invisible',
    callback: (recaptchaToken) => {
      // console.log('recaptchaToken', recaptchaToken);
    },
  });
};
