export const isLength = (value, min = 5, max = 16) => {
  return value.trim().length >= min && value.trim().length <= max;
};

/**
 * @param {String} value
 */
export const isOtpCode = (value) => {
  return /^\d{6}$/.test(value.trim());
};


/**
 * @param {String} value
 */
 export const isDateOfBirthValid = (value) => {
  return value !== '' && parseInt(value) > 1900 && parseInt(value) < 2021;
};