import { useHistory } from 'react-router';
import useHttp from '../../hooks/use-http';
import { useEffect, useRef, useState } from 'react';
import classes from './RegisterInfo.module.css';
import logo from '../../assets/images/logo.png';
import Card from '../ui/Card';
import { isPhoneNumber, normalizePhoneNumber } from '../../libs/phone-number';
import { isDateOfBirthValid, isOtpCode } from '../../libs/validation';
import { submitRegister } from '../../libs/api';
import useFirebaseAuth from '../../hooks/use-firebase-auth';
import { registerRecaptchaVerifier } from '../../libs/firebase-auth-api';
import LoadingSpinner from '../ui/LoadingSpinner';
import InlineError from '../error/InlineError';
import React from 'react';
const RegisterInfo = (props) => {
  const { replace } = useHistory();

  const [startSubmit, setStartSubmit] = useState(false);

  const fullNameInputRef = useRef();
  const [fullNameIsValid, setFullNameIsValid] = useState(false);
  const [fullNameIsTouched, setFullNameIsTouched] = useState(false);

  const phoneNumberInputRef = useRef();
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
  const [phoneNumberIsTouched, setPhoneNumberIsTouched] = useState(false);

  const ageInputRef = useRef();
  const [ageIsValid, setAgeIsValid] = useState(false);
  const [ageIsTouched, setAgeIsTouched] = useState(false);

  const addressInputRef = useRef();
  const [addressIsValid, setAddressIsValid] = useState(false);
  const [addressIsTouched, setAddressIsTouched] = useState(false);

  const bookDateInputRef = useRef();
  const [bookDateIsValid, setBookDateIsValid] = useState(false);
  const [bookDateIsTouched, setBookDateIsTouched] = useState(false);

  const otpCodeInputRef = useRef();
  const [otpCodeIsValid, setOtpCodeIsValid] = useState(false);
  const [otpCodeIsTouched, setOtpCodeIsTouched] = useState(false);

  const companyNameInputRef = useRef();
  const companyCodeInputRef = useRef();

  const [formIsValid, setFormValid] = useState(false);

  const [ho, setHo] = useState('');
  const [sot, setSot] = useState('');
  const [khoTho, setKhoTho] = useState('');
  const [dauHong, setDauHong] = useState('');
  const [metMoi, setMetMoi] = useState('');
  const [tiepXucNghiNhiem, setTiepXucNghiNhiem] = useState('');
  const [veTuVungDich, setVeTuVungDich] = useState('');
  const [tiepXucNguoiVeTuVungDich, setTiepXucNguoiVeTuVungDich] = useState('');
  const [isAgree, setIsAgree] = useState(false);

  const hourInputRef = useRef();
  const minInputRef = useRef();

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    if (name === 'ho') {
      setHo(value);
    } else if (name === 'sot') {
      setSot(value);
    } else if (name === 'khoTho') {
      setKhoTho(value);
    } else if (name === 'dauHong') {
      setDauHong(value);
    } else if (name === 'metMoi') {
      setMetMoi(value);
    } else if (name === 'tiepXucNghiNhiem') {
      setTiepXucNghiNhiem(value);
    } else if (name === 'veTuVungDich') {
      setVeTuVungDich(value);
    } else if (name === 'tiepXucNguoiVeTuVungDich') {
      setTiepXucNguoiVeTuVungDich(value);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsAgree(e.target.checked);
  };

  const {
    sendRequest: doSubmitRegister,
    error: httpError,
    status: httpStatus,
  } = useHttp(submitRegister);

  const {
    sendRequest: sendRequestFirebase,
    status: firebaseStatus,
    signedIn,
    confirmedCode,
    token,
    error: firebaseError,
  } = useFirebaseAuth();

  const isProcessing = httpStatus === 'pending' || firebaseStatus === 'pending';
  const error = httpError || firebaseError;

  const getOtpCodeHandler = async () => {
    if (isProcessing) {
      return;
    }
    const fullNameValue = fullNameInputRef.current.value;
    const phoneNumberValue = phoneNumberInputRef.current.value;
    const ageValue = ageInputRef.current.value;
    const addressValue = addressInputRef.current.value;
    const bookDateValue = bookDateInputRef.current.value;

    const fullNameIsValid = fullNameValue !== '';
    setFullNameIsValid(fullNameIsValid);
    setFullNameIsTouched(true);

    const phoneNumberIsValid = isPhoneNumber(phoneNumberValue);
    setPhoneNumberIsValid(phoneNumberIsValid);
    setPhoneNumberIsTouched(true);

    const ageIsValid = isDateOfBirthValid(ageValue);
    setAgeIsValid(ageIsValid);
    setAgeIsTouched(true);

    const addressIsValid = addressValue !== '';
    setAddressIsValid(addressIsValid);
    setAddressIsTouched(true);

    const bookDateIsValid = bookDateValue !== '';
    setBookDateIsValid(bookDateIsValid);
    setBookDateIsTouched(true);
    

    const isHeathDeclaration =
      ho !== '' &&
      sot !== '' &&
      khoTho !== '' &&
      dauHong !== '' &&
      metMoi !== '' &&
      tiepXucNghiNhiem !== '' &&
      veTuVungDich !== '' &&
      tiepXucNguoiVeTuVungDich !== '';

    const formIsValid =
      fullNameIsValid &&
      phoneNumberIsValid &&
      ageIsValid &&
      addressIsValid &&
      bookDateIsValid;

    if (!formIsValid) {
      alert('Vui lòng điền đầy đủ thông tin cá nhân');
      return;
    }

    if (!isHeathDeclaration) {
      alert('Vui lòng khai báo y tế');
      return;
    }

    if (!isAgree) {
      alert('Bạn vui lòng check vào ô xác nhận');
      return;
    }
    setFormValid(true);
    const normalizedPhoneNumber = normalizePhoneNumber(phoneNumberValue);
    sendRequestFirebase(normalizedPhoneNumber);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const enteredOtpCode = otpCodeInputRef.current.value;
    const otpCodeIsValid = isOtpCode(enteredOtpCode);
    setOtpCodeIsValid(otpCodeIsValid);
    if (formIsValid && otpCodeIsValid) {
      sendRequestFirebase(enteredOtpCode);
    }
  };

  const blurFullnameHandler = () => {
    setFullNameIsValid(fullNameInputRef.current.value !== '');
    setFullNameIsTouched(true);
  };

  const blurPhoneNumberHandler = () => {
    setPhoneNumberIsValid(isPhoneNumber(phoneNumberInputRef.current.value));
    setPhoneNumberIsTouched(true);
  };

  const blurAgeHandler = () => {
    const ageValue = ageInputRef.current.value;
    setAgeIsValid(isDateOfBirthValid(ageValue));
    setAgeIsTouched(true);
  };

  const blurAddressHandler = () => {
    const addressIsValid = addressInputRef.current.value;
    setAddressIsValid(addressIsValid);
    setAddressIsTouched(true);
  };

  const blurBookDateHandler = () => {
    const bookDateIsValid = bookDateInputRef.current.value;
    setBookDateIsValid(bookDateIsValid);
    setBookDateIsTouched(true);
  };

  const blurOtpCodeHandler = () => {
    setOtpCodeIsValid(isOtpCode(otpCodeInputRef.current.value));
    setOtpCodeIsTouched(true);
  };

  useEffect(() => {
    registerRecaptchaVerifier();
  }, []);

  useEffect(() => {
    if (confirmedCode && token !== '' && !startSubmit) {
      setStartSubmit(true);
      const postData = {
        fullName: fullNameInputRef.current.value,
        phoneNumber: phoneNumberInputRef.current.value,
        companyName: companyNameInputRef.current.value,
        companyCode: companyCodeInputRef.current.value,
        age: ageInputRef.current.value,
        address: addressInputRef.current.value,
        bookedDate: bookDateInputRef.current.value,
        hour: hourInputRef.current.value,
        min: minInputRef.current.value,
        ho: ho,
        sot: sot,
        khoTho: khoTho,
        dauHong: dauHong,
        metMoi: metMoi,
        tiepXucNghiNhiem: tiepXucNghiNhiem,
        veTuVungDich: veTuVungDich,
        tiepXucNguoiTuVungDich: tiepXucNguoiVeTuVungDich,
        token: token,
      };
      doSubmitRegister(postData);
    }
  }, [
    confirmedCode,
    replace,
    token,
    startSubmit,
    doSubmitRegister,
    ho,
    sot,
    khoTho,
    dauHong,
    metMoi,
    tiepXucNghiNhiem,
    veTuVungDich,
    tiepXucNguoiVeTuVungDich,
  ]);

  useEffect(() => {
    let isSubmitSuccess = true;
    if (error) {
      const status = error.errorCode || error.httpCode;
      isSubmitSuccess = status === 204;
    }
    if (
      confirmedCode &&
      token !== '' &&
      httpStatus === 'completed' &&
      isSubmitSuccess
    ) {
      replace('/register/success');
    }
  }, [httpStatus, confirmedCode, token, replace, error]);

  const fullnameClass =
    !fullNameIsValid && fullNameIsTouched
      ? `${classes.control} ${classes.error}`
      : classes.control;
  const phoneNumberClass =
    !phoneNumberIsValid && phoneNumberIsTouched
      ? `${classes.control} ${classes.error}`
      : classes.control;
  const ageClass =
    !ageIsValid && ageIsTouched
      ? `${classes.control} ${classes.error}`
      : classes.control;

  const addressClass =
    !addressIsValid && addressIsTouched
      ? `${classes.control} ${classes.error}`
      : classes.control;

  const bookDateClass =
    !bookDateIsValid && bookDateIsTouched
      ? `${classes.control} ${classes.error}`
      : classes.control;

  const otpCodeClasses =
    !otpCodeIsValid && otpCodeIsTouched
      ? `${classes.control} ${classes.error}`
      : classes.control;

  return (
    <section className={classes.register}>
      <a href="/">
        <img src={logo} alt="Logo phòng khám" />
      </a>
      <h3 className={classes.title}>PHIẾU ĐẶT LỊCH HẸN XÉT NGHIỆM COVID-19</h3>
      <Card>
        {isProcessing && <LoadingSpinner />}
        <form onSubmit={submitHandler}>
          <h4 className={classes.personalInfoTitle}>I. Thông tin cá nhân</h4>
          <div className={fullnameClass}>
            <label htmlFor="fullname">Họ tên</label>
            <input type="text" name="fullname" id="fullname" placeholder="Ví dụ: Nguyễn Văn A"  ref={fullNameInputRef} onBlur={blurFullnameHandler} />
          </div>
          <div className={phoneNumberClass}>
            <label htmlFor="phoneNumber">Điện thoại</label>
            <input type="tel" name="phoneNumber" id="phoneNumber" placeholder="Ví dụ: 0988990099" disabled={signedIn} ref={phoneNumberInputRef} onBlur={blurPhoneNumberHandler} />
          </div>
          <div className={ageClass}>
            <label htmlFor="age">Năm sinh</label>
            <input type="number" name="age" id="age" placeholder="Ví dụ: 1991"  ref={ageInputRef} onBlur={blurAgeHandler} />
          </div>
          <div className={classes.control}>
            <label htmlFor="sex">Giới tính</label>
            <select name="sex" id="sex" >
              <option value="male">Nam</option>
              <option value="female">Nữ</option>
            </select>
          </div>
          <div className={addressClass}>
            <label htmlFor="address">Địa chỉ</label>
            <input type="text" name="address" id="address" placeholder="Ví dụ: 412A/45 Cách Mạng Tháng 8, P. Cái Khế, Q. Ninh Kiều, Tp. Cần Thơ" ref={addressInputRef} onBlur={blurAddressHandler} />
          </div>
          <div className={bookDateClass}>
            <label htmlFor="bookDate">Ngày khám</label>
            <input type="date" name="bookDate" id="bookDate" ref={bookDateInputRef} onBlur={blurBookDateHandler} />
          </div>
          <div className={bookDateClass}>
            <label htmlFor="bookDate">Giờ khám</label>
            <table>
              <tbody>
                <tr>
                  <td>
                    <select name="hour" id="hour" ref={hourInputRef} >
                      <option value="7">7 giờ</option>
                      <option value="8">8 giờ</option>
                      <option value="9">9 giờ</option>
                      <option value="10">10 giờ</option>
                      <option value="11">11 giờ</option>
                      <option value="12">12 giờ</option>
                      <option value="13">13 giờ</option>
                      <option value="14">14 giờ</option>
                      <option value="15">15 giờ</option>
                      <option value="16">16 giờ</option>
                      <option value="17">17 giờ</option>
                      <option value="18">18 giờ</option>
                    </select>
                  </td>
                  <td>
                    <select name="min" id="min" ref={minInputRef}>
                      <option value="0">0 phút</option>
                      <option value="30">30 phút</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className={classes.personalInfoTitle}>II. Thông tin công ty (nếu bạn đăng ký cho cá nhân, gia đình có thể bỏ qua mục này)</h4>
          <div className={fullnameClass}>
              <label htmlFor="fullname">Tên cty</label>
              <input type="text" name="companyName" id="companyName" placeholder="Ví dụ: CTY TNHH TMDV ABC" ref={companyNameInputRef} />
          </div>

          <div className={fullnameClass}>
              <label htmlFor="companyCode">Mã cty</label>
              <input type="text" name="companyCode" id="companyCode"  ref={companyCodeInputRef} />
          </div>

          <br />
          <h4>III. Khai báo y tế</h4>
          <p>1. Triệu chứng</p>
          <table>
            <tbody>
              <tr>
                <td>a) Ho</td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="ho" value="true"  onChange={handleRadioChange} /> Có 
                  </label>
                </td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="ho" value="false"  onChange={handleRadioChange} />  Không 
                  </label>
                </td>
              </tr>
              <tr>
                <td>b) Sốt</td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="sot" value="true"  onChange={handleRadioChange} /> Có
                  </label>
                </td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="sot" value="false"  onChange={handleRadioChange} /> Không
                  </label>
                </td>
              </tr>
              <tr>
                <td>c) Khó thở</td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="khoTho" value="true" onChange={handleRadioChange} /> Có
                  </label>
                </td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="khoTho" value="false"  onChange={handleRadioChange} /> Không
                  </label>
                </td>
              </tr>
              <tr>
                <td>c) Đau họng</td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="dauHong" value="true"  onChange={handleRadioChange} /> Có
                  </label>
                </td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="dauHong" value="false"  onChange={handleRadioChange} /> Không
                  </label>
                </td>
              </tr>
              <tr>
                <td>c) Mệt mỏi</td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="metMoi" value="true"  onChange={handleRadioChange} /> Có
                  </label>
                </td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="metMoi" value="false"  onChange={handleRadioChange} /> Không
                  </label>
                </td>
              </tr>
            </tbody>
          </table>

          <p>2. Trong 14 ngày qua, anh/chị có:</p>
          <table>
            <tbody>
              <tr>
                <td colSpan="2">
                  Tiếp xúc với người bệnh Covid-19 hay nghi nghiễm bệnh không?
                </td>
              </tr>
              <tr>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="tiepXucNghiNhiem" value="true" onChange={handleRadioChange} /> Có
                  </label>
                </td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="tiepXucNghiNhiem" value="false"  onChange={handleRadioChange} /> Không
                  </label>
                </td>
              </tr>

              <tr className={classes.rowSpace10}>
                <td colSpan="2">Có đi về từ vùng dịch không?</td>
              </tr>
              <tr>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="veTuVungDich" value="true"  onChange={handleRadioChange} /> Có
                  </label>
                </td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="veTuVungDich" value="false"  onChange={handleRadioChange} /> Không
                  </label>
                </td>
              </tr>
              <tr className={classes.rowSpace10}>
                <td colSpan="2">
                  Có tiếp xúc với người đi về từ vùng dịch không?
                </td>
              </tr>
              <tr>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio"  name="tiepXucNguoiVeTuVungDich" value="true" onChange={handleRadioChange} /> Có
                  </label>
                </td>
                <td className={classes.colOption}>
                  <label>
                    <input type="radio" name="tiepXucNguoiVeTuVungDich" value="false"  onChange={handleRadioChange} /> Không
                  </label>
                </td>
              </tr>
              <tr className={classes.rowSpace10}>
                <td colSpan="2">
                  <label className={classes.labelConfirm}>
                    <input type="checkbox" name="checkAgree" disabled={signedIn} onChange={handleCheckboxChange} /> 
                    Xác nhận, tôi xin cam kết những thông tin trên là đúng sự
                    thật, tôi hiểu rằng nếu cung cấp sai thông tin có thể dẫn
                    đến những hậu quả nghiêm trọng!
                  </label>
                </td>
              </tr>
            </tbody>
          </table>

          {!formIsValid && (
            <div className={classes.actions}>
              <button id="otpCodeBtn" type="button" onClick={getOtpCodeHandler}>
                Lấy mã OTP
              </button>
            </div>
          )}

          {formIsValid && (
            <div className={otpCodeClasses}>
              <label htmlFor="otpCode">Mã xác thực</label>
              <input type="text" name="otpCode" id="otpCode" placeholder="Bao gồm 6 chữ số" className={classes.optInput} ref={otpCodeInputRef} onBlur={blurOtpCodeHandler} />
              {!otpCodeIsValid && otpCodeIsTouched && (
                <span>Mã xác thực bao gồm 6 chứ số, ví dụ: 338833</span>
              )}
            </div>
          )}
          {error && <InlineError error={error} />}
          {formIsValid && (
            <div className={classes.actions}>
              <button id="otpCodeBtn" type="button" onClick={submitHandler}>
                Hoàn thành đăng ký
              </button>
            </div>
          )}
        </form>
      </Card>
    </section>
  );
};

export default RegisterInfo;
