import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import classes from './ErrorSupport.module.css';

const ErrorSupport = (props) => {
  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    authCtx.logout();
  };

  return (
    <div className={classes.support}>
      <p className={classes.logout}>
        Vui lòng <button onClick={logoutHandler}>thử lại</button>
      </p>
      <p className={classes.call}>
        Hoặc liên hệ SĐT <a href="tel:+842923827179">0292 382 7179</a> để được
        hỗ trợ.
      </p>
    </div>
  );
};

export default ErrorSupport;
