import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import classes from './MainNavigation.module.css';

const MainNavigation = (props) => {
  const authCtx = useContext(AuthContext);

  const submitHandler = async (event) => {
    event.preventDefault();
    authCtx.logout();
  };

  return (
    <header className={classes.header}>
      <div className={classes.logo}>
        <div>Phòng khám</div>
        <div>ĐỒNG XUÂN</div>
      </div>
      {authCtx.isLoggedIn && (
        <form onSubmit={submitHandler} className={classes.form}>
          <button>Đăng xuất</button>
        </form>
      )}
    </header>
  );
};

export default MainNavigation;
