import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import ResultListPage from '../pages/ResultListPage';
import CovidTestResultPage from '../pages/CovidTestResultPage';
import HealthExaminationPage from '../pages/HealthExaminationPage';

const ResultsRoute = (props) => {
  const match = useRouteMatch();
  const rootPath = match.path;

  return (
    <Switch>
      <Route path={rootPath} exact>
        <ResultListPage />
      </Route>
      <Route path={`${rootPath}/covid-19/:resultId`}>
        <CovidTestResultPage />
      </Route>
      <Route path={`${rootPath}/ksk/:resultId`}>
        <HealthExaminationPage />
      </Route>      
      <Route path="*">
        <Redirect to={rootPath} />
      </Route>
    </Switch>
  );
};

export default ResultsRoute;
