import classes from './ClinicalDiagnosis.module.css';

const InternalMedicine = (props) => {
  const values = props.value || [];
  const index = props.index || 'N/A';

  return (
    <div className={classes['multiple-line']}>
      <h4 className={classes.title}>{index}. Nội khoa</h4>
      {values.map((item, index) => (
        <div key={String.fromCharCode(97 + index)} className={classes.row}>
          <div>
            {String.fromCharCode(97 + index)}) {item.label}:
          </div>
          <div>{item.value}</div>
          <div>Phân loại: {item.classification}</div>
        </div>
      ))}
    </div>
  );
};

export default InternalMedicine;
