import classes from './RegisterSuccessPage.module.css';
import successIcon from '../assets/images/success.gif';

const RegisterSuccessPage = (props) => {
  return (
    <div className={classes.container}>
      <img src={successIcon} alt='success' width='150px'/>
      <h1>Bạn đã đặt lịch hẹn thành công</h1>
      <p>Cám ơn bạn đã tin tưởng và sử dụng dịch vụ của chúng tôi, khi đến phòng khám vui lòng tuân thủ 5K để phòng chống dịch. Xin chân thành cảm ơn</p>
      <p><a href='/'>Về trang chủ</a></p>
    </div>
  );
};

export default RegisterSuccessPage;
