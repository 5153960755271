import classes from './ReportHeader.module.css';
import logo from '../assets/images/logo.png';

const ReportHeader = (props) => {
  return (
    <section className={classes.section}>
      <img src={logo} alt="Logo" />
      <div className={classes.info}>
        <p className={classes['clinic-name']}>PHÒNG KHÁM ĐA KHOA ĐỒNG XUÂN</p>
        <address className={classes['clinic-address']}>
          Địa chỉ: 179 Nguyễn Văn Linh, P. An Khánh, Q. Ninh Kiều, Tp Cần Thơ
        </address>
        <address className={classes['phone-email']}>
          Điện thoại: <a href="tel:+842923827179">0292 382 7179</a> - Email:{' '}
          <a href="mailto:phongkhamdongxuan@gmail.com">
            phongkhamdongxuan@gmail.com
          </a>
        </address>
      </div>
    </section>
  );
};

export default ReportHeader;
