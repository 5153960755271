import classes from './Signature.module.css';

const Signature = (props) => {
  const { date, doctor } = props;

  const testDate = new Date(date);
  const hours = testDate.getHours();
  const minutes = testDate.getMinutes();
  const day = testDate.getDate();
  const month = testDate.getMonth() + 1;
  const year = testDate.getFullYear();

  const formattedDate = `${hours} giờ ${minutes}, ngày ${day} tháng ${month} năm ${year}`;

  return (
    <section className={classes.container}>
      <div className={classes.signature}>
        <div className={classes.date}>{formattedDate}</div>
        <div className={classes.title}>NGƯỜI THỰC HIỆN</div>
        <div className={classes.doctor}>{doctor}</div>
      </div>
    </section>
  );
};

export default Signature;
