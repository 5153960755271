import AuthForm from '../components/auth/AuthForm';
import NormalSupport from '../components/error/NormalSupport';
import classes from './AuthPage.module.css';

const AuthPage = (props) => {
  return (
    <div className={classes.auth}>
      <AuthForm />
      <NormalSupport />
    </div>
  );
};

export default AuthPage;
