import classes from './ClinicalDiagnosis.module.css';

const Tooth = (props) => {
  const data = props.value || {
    chewIndex: 'N/A',
    teethUp: 'N/A',
    teethDown: 'N/A',
    diseases: 'N/A',
    classification: 'N/A',
  };
  const index = props.index || 'N/A';

  return (
    <div className={`${classes.mixed} ${classes.section}`}>
      <h4 className={classes.title}>{index}. Răng hàm mặt</h4>
      <div>- Kết quả khám</div>
      <div className={classes.row}>
        <div>Hệ số nhai: {data.chewIndex}</div>
        <div>Hàm trên: {data.teethUp}</div>
        <div>Hàm dưới: {data.teethDown}</div>
      </div>
      <div>- Các bệnh về răng hàm mặt (nếu có): {data.diseases}</div>
      <div>- Phân loại: {data.classification}</div>
    </div>
  );
};

export default Tooth;
