import RegisterInfo from '../components/register/RegisterInfo';
import classes from './RegisterPage.module.css';
const RegisterPage = (props) => {
  return (
    <div className={classes.register}>
      <RegisterInfo/> 
    </div>
    
  );
};

export default RegisterPage;
