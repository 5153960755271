import classes from './CovidTestInfo.module.css';

const CovidUserInfo = (props) => {
  const { diagnosis, appointedDoctor } = props;

  return (
    <section className={classes.info}>
      <div className={classes.detail}>Chẩn đoán: {diagnosis}</div>
      <div className={classes.detail}>Bác sĩ chỉ định: {appointedDoctor}</div>
    </section>
  );
};

export default CovidUserInfo;
