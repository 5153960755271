export const verifyPhoneNumber = (phoneNumber) => {
  return false;
};

/**
 * @param {String} phoneNumber
 */
export const normalizePhoneNumber = (phoneNumber) => {
  const trimmed = phoneNumber.trim();
  if (trimmed.startsWith('0')) {
    return '+84' + trimmed.substring(1);
  }
  return trimmed;
};

/**
 * @param {String} phoneNumber
 */
export const isPhoneNumber = (phoneNumber, doNormalize = false) => {
  let adjustedPhone = phoneNumber;
  if (doNormalize) {
    adjustedPhone = normalizePhoneNumber(phoneNumber);
  }
  return /^(0|\+84)\d{9,9}$/.test(adjustedPhone);
};
