import classes from './BackgroundMessage.module.css';

const BackgroundMessage = (props) => {
  const className = `${classes.message} ${props.className}`

  return (
    <div className={className}>
      {props.inlineMessage ? (
        <p className={classes.inline}>{props.inlineMessage}</p>
      ) : (
        props.children
      )}
    </div>
  );
};

export default BackgroundMessage;
