const EMPTY = '-';

export const mapsFromServerToClient = (rawData) => {
  const user = rawData.benhNhan;
  const subclinical = rawData.xetNghiems;

  const userProfile = {
    companyName: user.tenCty || 'N/A',
    dateTaken: user.ngayKham || 'N/A',
    employeeId: user.ma || 'N/A',
    fullName: user.ten || 'N/A',
    gender: user.gioiTinh || 'N/A',
    age: user.tuoi || 'N/A',
    phoneNumber: user.dienThoai || 'N/A',
    department: user.phongBan || 'N/A',
  };

  const physicalStrength = {
    weight: user.canNang || 'N/A',
    height: user.chieuCao || 'N/A',
    bloodPressure: user.huyetAp || 'N/A',
    heartBeat: user.mach || 'N/A',
    classification: user.phanLoaiTheLuc || 'N/A',
  };

  const internalMedicine = [
    {
      label: 'Tuần hoàn',
      value: user.tuanHoan || 'N/A',
      classification: user.tuanHoanPL || 'N/A',
    },
    {
      label: 'Nội tiết',
      value: user.noiTiet || 'N/A',
      classification: user.noiTietPL || 'N/A',
    },
    {
      label: 'Hô hấp',
      value: user.hoHap || 'N/A',
      classification: user.hoHapPL || 'N/A',
    },
    {
      label: 'Tiêu hoá',
      value: user.tieuHoa || 'N/A',
      classification: user.tieuHoaPL || 'N/A',
    },
    {
      label: 'Thận - Tiết niệu',
      value: user.thanTietNieu || 'N/A',
      classification: user.thanTietNieuPL || 'N/A',
    },
    {
      label: 'Xương khớp',
      value: user.coXuongKhop || 'N/A',
      classification: user.coXuongKhopPL || 'N/A',
    },
    {
      label: 'Thần kinh',
      value: user.thanKinh || 'N/A',
      classification: user.thanKinhPL || 'N/A',
    },
    {
      label: 'Tâm thần',
      value: user.tamThan || 'N/A',
      classification: user.tamThanPL || 'N/A',
    },
  ];

  const surgery = {
    value: user.ngoaiKhoa || 'N/A',
    classification: user.ngoaiKhoaPL || 'N/A',
  };

  // TODO: Male won't have this field
  const obstetrics = {
    value: user.sanPhuKhoa || 'N/A',
    classification: user.sanPhuKhoaPL || 'N/A',
  };

  const eyes = {
    hasGlasses: user.coKinhKhongKinh || 'N/A',
    leftEye: user.matTrai || 'N/A',
    rightEye: user.matPhai || 'N/A',
    diseases: user.cacBenhVeMat || 'N/A',
    classification: user.phanLoaiMat || 'N/A',
  };

  const earNoseMouth = {
    leftEarNormalTalk: user.taiTraiNoiThuong || 'N/A',
    leftEarTinyTalk: user.taiTraiNoiTham || 'N/A',
    rightEarNormalTalk: user.taiPhaiNoiThuong || 'N/A',
    rightEarTinyTalk: user.taiPhaiNoiTham || 'N/A',
    diseases: user.cacBenhVeTaiMuiHong || 'N/A',
    classification: user.taiMuiHongPL || 'N/A',
  };

  const tooth = {
    chewIndex: user.heSoNhai || 'N/A',
    teethUp: user.hamTren || 'N/A',
    teethDown: user.hamDuoi || 'N/A',
    diseases: user.cacBenhVeRangHamMat || 'N/A',
    classification: user.rangHamMatPL || 'N/A',
  };

  const skin = {
    value: user.daLieu || 'N/A',
    classification: user.daLieuPL || 'N/A',
  };

  const clinicalDiagnosis = {
    internalMedicine,
    surgery,
    obstetrics,
    skin,
    eyes,
    earNoseMouth,
    tooth,
  };

  const subclinicalDiagnosis = subclinical.map((subclinical) => {
    return {
      name: subclinical.ten || 'N/A',
      results: subclinical.ketQuas.map((result) => {
        return {
          name: result.ten || 'N/A',
          value: result.ketQua || 'N/A',
          normalResult: result.dvt || 'N/A',
        };
      }),
    };
  });

  const overallHealthClass = user.phanLoaiSucKhoe || 'N/A';
  const adviceFromDoctor = user.loiDanCuaBs || 'N/A';

  return {
    userProfile,
    physicalStrength,
    clinicalDiagnosis,
    subclinicalDiagnosis,
    overallHealthClass,
    adviceFromDoctor,
  };
};

export const mapResults = (rawData) => {
  if (!rawData || rawData.length === 0) {
    return [];
  }
  const data = [];
  rawData.forEach((item) => {
    const transformed = {
      id: item.id,
      date: item.ngay,
      name: item.ten,
      type: item.type,
    };
    data.push(transformed);
  });
  return data;
};

export const mapCovidTestResult = (rawData) => {
  const user = {
    name: rawData.ten,
    gender: rawData.gioiTinh || EMPTY,
    age: rawData.tuoi || EMPTY,
    phoneNumber: rawData.sdt || EMPTY,
    address: rawData.diaChi || EMPTY,
  };

  const testInfo = {
    diagnosis: rawData.chuanDoan || EMPTY,
    appointedDoctor: rawData.bsChiDinh || EMPTY,
    testDate: rawData.ngay,
    testDoctor: rawData.nguoiXetNghiem,
  };

  const result = {
    name: rawData.cachXetNghiem,
    result: rawData.ketQua,
    normal: rawData.triSoBinhThuong || EMPTY,
  };
  return { user, testInfo, result };
};
