import classes from './InlineError.module.css';

/**
 * @typedef {{technicalMessage: String; httpCode?: Number, errorCode?: String}} Error
 * @param {{error: Error}} props
 */
const InlineError = (props) => {
  const error = props.error.errorCode || props.error.httpCode;

  let friendlyMessage;
  switch (error.toString()) {
    case '401':
      friendlyMessage = 'Hãy kiểm tra lại tên đăng nhập và mật khẩu';
      break;
    case 'auth/phone/401':
      friendlyMessage = 'Số điện thoại của bạn ko tồn tại trong hệ thống';
      break;
    case 'auth/invalid-phone-number':
      friendlyMessage = 'Vui lòng nhập đúng số điện thoại :((';
      break;
    case 'auth/invalid-verification-code':
      friendlyMessage = 'Ko đúng mã xác thực, vui lòng kiểm tra lại';
      break;
    case 'auth/missing-phone-number':
      friendlyMessage = 'Sao lại quên nhập số điện thoại được cơ chớ :((';
      break;
    case 'auth/missing-verification-code':
      friendlyMessage = 'Sao lại thiếu mã xác thực được cơ chớ? :((';
      break;
    case 'registration/company-code/404':
      friendlyMessage = 'Mã công ty không tồn tại';
      break;
    default:
      friendlyMessage =
        props.unknownMessage ||
        'Úpp... Ko thể xử lý yêu cầu, vui lòng thử lại sau :((';
      break;
  }

  return (
    <div className={classes.error}>
      <p>{friendlyMessage}</p>
    </div>
  );
};

export default InlineError;
