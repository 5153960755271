import ReactDOM from 'react-dom';
import classes from './LoadingSpinner.module.css';
import Spinner from './Spinner';

const Backdrop = (props) => {
  return <div className={classes.backdrop}>{props.children}</div>;
};

const LoadingSpinner = (props) => {
  return ReactDOM.createPortal(
    <Backdrop>
      <Spinner />
    </Backdrop>,
    document.getElementById('overlay-root')
  );
};

export default LoadingSpinner;
