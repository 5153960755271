import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import useFirebaseAuth from '../../hooks/use-firebase-auth';
import useHttp from '../../hooks/use-http';
import { getPhoneExisting } from '../../libs/api';
import { registerRecaptchaVerifier } from '../../libs/firebase-auth-api';
import { isPhoneNumber, normalizePhoneNumber } from '../../libs/phone-number';
import { isOtpCode } from '../../libs/validation';
import AuthContext from '../../store/auth-context';
import InlineError from '../error/InlineError';
import Card from '../ui/Card';
import LoadingSpinner from '../ui/LoadingSpinner';
import classes from './AuthForm.module.css';

const PhoneNumberForm = (props) => {
  const { replace } = useHistory();
  const [normalizedPhoneNumber, setNormalizedPhoneNumber] = useState('');
  const authCtx = useContext(AuthContext);
  const {
    sendRequest: checkPhoneExisting,
    data: isPhoneExisted,
    error: httpError,
    status: httpStatus,
  } = useHttp(getPhoneExisting);
  const {
    sendRequest: sendRequestFirebase,
    status: firebaseStatus,
    signedIn,
    confirmedCode,
    token,
    error: firebaseError,
  } = useFirebaseAuth();
  const isProcessing = firebaseStatus === 'pending' || httpStatus === 'pending';
  const error = httpError || firebaseError;
  const isNotRequestOtp = !firebaseStatus;

  const phoneNumberInputRef = useRef();
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
  const [phoneNumberIsTouched, setPhoneNumberIsTouched] = useState(false);

  const otpCodeInputRef = useRef();
  const [otpCodeIsValid, setOtpCodeIsValid] = useState(false);
  const [otpCodeIsTouched, setOtpCodeIsTouched] = useState(false);

  const login = authCtx.login;

  const submitHandler = async (event) => {
    event.preventDefault();
    setOtpCodeIsTouched(true);
    if (isProcessing) {
      return;
    }
    if (isNotRequestOtp) {
      return getOtpCodeHandler();
    }
    const enteredOtpCode = otpCodeInputRef.current.value;
    const otpCodeIsValid = isOtpCode(enteredOtpCode);
    setOtpCodeIsValid(otpCodeIsValid);
    const formIsValid = phoneNumberIsValid && otpCodeIsValid;
    if (!formIsValid) {
      return;
    }
    sendRequestFirebase(enteredOtpCode);
  };

  const getOtpCodeHandler = async () => {
    setPhoneNumberIsTouched(true);
    if (isProcessing) {
      return;
    }
    const enteredPhoneNumber = phoneNumberInputRef.current.value;
    const normalizedPhoneNumber = normalizePhoneNumber(enteredPhoneNumber);
    const phoneNumberIsValid = isPhoneNumber(normalizedPhoneNumber);
    setPhoneNumberIsValid(phoneNumberIsValid);
    if (!phoneNumberIsValid) {
      return;
    }
    setNormalizedPhoneNumber(normalizedPhoneNumber);
    checkPhoneExisting(enteredPhoneNumber);
  };

  const blurPhoneNumberHandler = () => {
    setPhoneNumberIsValid(isPhoneNumber(phoneNumberInputRef.current.value));
    setPhoneNumberIsTouched(true);
  };

  const blurOtpCodeHandler = () => {
    setOtpCodeIsValid(isOtpCode(otpCodeInputRef.current.value));
    setOtpCodeIsTouched(true);
  };

  useEffect(() => {
    registerRecaptchaVerifier();
  }, []);

  useEffect(() => {
    if (isPhoneExisted && !signedIn) {
      sendRequestFirebase(normalizedPhoneNumber);
    }
  }, [sendRequestFirebase, isPhoneExisted, normalizedPhoneNumber, signedIn]);

  useEffect(() => {
    if (confirmedCode) {
      login('phone', token);
      replace('/results');
    }
  }, [confirmedCode, replace, login, token]);

  const phoneNumberClasses =
    !phoneNumberIsValid && phoneNumberIsTouched
      ? `${classes.control} ${classes.error}`
      : classes.control;

  const otpCodeClasses =
    !otpCodeIsValid && otpCodeIsTouched
      ? `${classes.control} ${classes.error}`
      : classes.control;

  return (
    <Card>
      {isProcessing && <LoadingSpinner />}
      <form onSubmit={submitHandler}>
        <div className={phoneNumberClasses}>
          <label htmlFor="phoneNumber">Số điện thoại</label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Ví dụ: 0939123456"
            disabled={signedIn}
            ref={phoneNumberInputRef}
            onBlur={blurPhoneNumberHandler}
          />
          {!phoneNumberIsValid && phoneNumberIsTouched && (
            <span>
              Vui lòng nhập đúng số điện thoại, có thể bắt đầu với 0 hoặc +84
            </span>
          )}
        </div>
        {signedIn && (
          <div className={otpCodeClasses}>
            <label htmlFor="otpCode">Mã xác thực</label>
            <input
              type="text"
              name="otpCode"
              id="otpCode"
              placeholder="Bao gồm 6 chữ số"
              ref={otpCodeInputRef}
              onBlur={blurOtpCodeHandler}
            />
            {!otpCodeIsValid && otpCodeIsTouched && (
              <span>Mã xác thực bao gồm 6 chứ số, ví dụ: 338833</span>
            )}
          </div>
        )}
        {error && <InlineError error={error} />}
        <div className={classes.actions}>
          {!signedIn && (
            <button id="otpCodeBtn" type="button" onClick={getOtpCodeHandler}>
              Lấy mã xác thực
            </button>
          )}
          {signedIn && <button id="submitBtn">Đăng nhập</button>}
          {/* <Link to="/auth/username">Đăng nhập bằng tài khoản</Link> */}
        </div>
      </form>
    </Card>
  );
};

export default PhoneNumberForm;
