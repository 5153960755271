import classes from './PhysicalStrength.module.css';

const PhysicalStrength = (props) => {
  const value = props.value || {};

  const bmi = ((value.weight * 10_000) / (value.height * value.height)).toFixed(
    2
  );

  return (
    <section>
      <h3 className={classes.title}>I. Khám thể lực</h3>
      <div className={classes.row}>
        <div>Cân nặng: {value.weight} kg</div>
        <div>Chiều cao: {value.height} cm</div>
        <div>BMI: {bmi}</div>
        <div>Huyết áp: {value.bloodPressure}</div>
        <div>Mạch: {value.heartBeat}</div>
        <div>Phân loại: {value.classification}</div>
      </div>
    </section>
  );
};
export default PhysicalStrength;
