import classes from './ClinicalDiagnosis.module.css';

const Eyes = (props) => {
  const data = props.value || {
    hasGlasses: 'N/A',
    leftEye: 'N/A',
    rightEye: 'N/A',
    diseases: 'N/A',
    classification: 'N/A',
  };
  const isGlasses = data.hasGlasses === 'Không kính' ? false: true;
  const index = props.index || 'N/A';

  return (
    <div className={`${classes.mixed} ${classes.section}`}>
      <h4 className={classes.title}>{index}. Mắt</h4>
      <div>
        - Kết quả khám thị lực: {isGlasses ? 'Có kính' : 'Không kính'}
      </div>
      <div className={classes.row}>
        <div>Mắt phải: {data.rightEye}/10</div>
        <div>Mắt trái: {data.leftEye}/10</div>
      </div>
      <div>- Các bệnh về mắt (nếu có): {data.diseases}</div>
      <div>- Phân loại: {data.classification}</div>
    </div>
  );
};

export default Eyes;
