import classes from './ClinicalDiagnosis.module.css';

const EarNoseMouth = (props) => {
  const data = props.value || {
    leftEarNormalTalk: 'N/A',
    leftEarTinyTalk: 'N/A',
    rightEarNormalTalk: 'N/A',
    rightEarTinyTalk: 'N/A',
    diseases: 'N/A',
    classification: 'N/A',
  };
  const index = props.index || 'N/A';

  return (
    <div className={`${classes.mixed} ${classes.section}`}>
      <h4 className={classes.title}>{index}. Tai - Mũi - Họng</h4>
      <div>- Kết quả khám thính lực</div>
      <div className={classes.row}>
        <div>Tai trái:</div>
        <div>Nói thường: {data.leftEarNormalTalk} m</div>
        <div>Nói thầm: {data.leftEarTinyTalk} m</div>
      </div>
      <div className={classes.row}>
        <div>Tai phải:</div>
        <div>Nói thường: {data.rightEarNormalTalk} m</div>
        <div>Nói thầm: {data.rightEarTinyTalk} m</div>
      </div>
      <div>- Các bệnh về tai mũi họng (nếu có): {data.diseases}</div>
      <div>- Phân loại: {data.classification}</div>
    </div>
  );
};

export default EarNoseMouth;
