import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useCallback, useState } from 'react';

const TOKEN = 'TOKEN';
const EXPIRES_AT = 'EXPIRES_AT';
const AUTH_TYPE = 'AUTH_TYPE';

const initialState = {
  isLoggedIn: false,
  authType: null,
  token: null,
  login: (authType, token, expiresAt) => {},
  logout: () => {},
};

const AuthContext = React.createContext(initialState);

let timer;

const getStoredToken = () => {
  const storedAuthType = localStorage.getItem(AUTH_TYPE);
  const storedExpiresAt = localStorage.getItem(EXPIRES_AT);
  const expiresIn = storedExpiresAt ? +storedExpiresAt - Date.now() : -1;
  const storedToken = expiresIn > 0 ? localStorage.getItem(TOKEN) : null;
  if (expiresIn <= 0) {
    localStorage.removeItem(AUTH_TYPE);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(EXPIRES_AT);
  }

  return {
    authType: storedAuthType,
    token: storedToken,
    expiresIn: expiresIn,
  };
};

const AuthContextProvider = (props) => {
  const tokenData = getStoredToken();
  const [token, setToken] = useState(tokenData.token);
  const [authType, setAuthType] = useState(tokenData.authType);
  const isLoggedIn = !!token;

  const logoutHandler = useCallback(async () => {
    await firebase.auth().signOut();
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(EXPIRES_AT);
    timer && clearTimeout(timer);
    setToken(null);
    setToken(null);
  }, []);

  const loginHandler = useCallback(
    (authType, token, expiresAt) => {
      const expiresIn = 60 * 60 * 1000;
      const updatedExpiresAt = expiresAt || Date.now() + expiresIn;
      localStorage.setItem(AUTH_TYPE, authType);
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(EXPIRES_AT, updatedExpiresAt);
      timer = setTimeout(logoutHandler, expiresIn);
      setToken(token);
      setAuthType(authType);
    },
    [logoutHandler]
  );

  if (tokenData.expiresIn > 0) {
    timer = setTimeout(logoutHandler, tokenData.expiresIn);
  }

  const contextValue = {
    isLoggedIn,
    authType,
    token,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider };

export default AuthContext;
