import { Fragment, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthPage from './pages/AuthPage';
import RegisterPage from './pages/RegisterPage';
import RegisterSuccessPage from './pages/RegisterSuccessPage';
import HomePage from './pages/HomePage';
import ResultsRoute from './routes/ResultsRoute';
import AuthContext from './store/auth-context';

function App() {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Fragment>
      <Switch>
        {!isLoggedIn && (
          <Route path="/auth">
            <AuthPage />
          </Route>
        )}
        <Route path="/results">
          {!isLoggedIn && <Redirect to="/auth" />}
          {isLoggedIn && <ResultsRoute />}
        </Route>        
        <Route path="/register" exact>
            <RegisterPage />
        </Route>
        <Route path="/register/success" exact>
            <RegisterSuccessPage />
        </Route>
        <Route path="/" exact>
            <HomePage />
        </Route>        
      </Switch>
    </Fragment>
  );
}

export default App;
