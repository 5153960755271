import classes from './UserProfile.module.css';

const UserProfile = (props) => {
  const value = props.value;

  return (
    <div className={classes.profile}>
      <div className={`${classes.item} ${classes.company}`}>
        Công ty: {value.companyName}
      </div>
      <div className={`${classes.item} ${classes.date}`}>
        Ngày khám: {value.dateTaken}
      </div>
      <div className={classes.item}>Mã NV: {value.employeeId}</div>
      <div className={`${classes.item} ${classes.name}`}>
        Họ tên: {value.fullName}
      </div>
      <div className={classes.item}>Giới tính: {value.gender}</div>
      <div className={`${classes.item} ${classes.age}`}>Tuổi: {value.age}</div>
      <div className={`${classes.item} ${classes.department}`}>
        Đơn vị: {value.department}
      </div>
      <div className={classes.item}>SĐT: {value.phoneNumber}</div>
    </div>
  );
};

export default UserProfile;
