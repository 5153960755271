import {
  mapCovidTestResult,
  mapResults,
  mapsFromServerToClient,
} from './data-transformer';

const baseUrl = process.env.REACT_APP_URL;

export const getHealthReport = async (requestData) => {
  const url = `${baseUrl}/report/detail?type=ksk&id=${requestData.resultId}&token=${requestData.token}`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('getHealthReport request failed @@');
    error.httpCode = response.status;
    throw error;
  }
  const rawData = await response.json();
  return mapsFromServerToClient(rawData);
};

export const loginByUsername = async (requestData) => {
  const response = await fetch(`${baseUrl}/auth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      employeeId: requestData.username,
      password: requestData.password,
    }),
  });

  if (!response.ok) {
    const error = new Error('loginByUsername request failed @@');
    error.httpCode = response.status;
    throw error;
  }
  const rawData = await response.json();
  return {
    token: rawData.token,
    expiresAt: rawData.ext,
  };
};

export const getResults = async (requestData) => {
  const url = `${baseUrl}/report?authType=${requestData.authType}&token=${requestData.token}`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('getResults request failed @@');
    error.httpCode = response.status;
    throw error;
  }

  const rawData = await response.json();
  return mapResults(rawData);
};

export const getCovidTestResult = async (requestData) => {
  const url = `${baseUrl}/report/detail?type=covid-19&id=${requestData.resultId}&token=${requestData.token}`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('getCovidTestResult request failed @@');
    error.httpCode = response.status;
    throw error;
  }

  const rawData = await response.json();
  return mapCovidTestResult(rawData);
};

export const getPhoneExisting = async (phoneNumber) => {
  const url = `${baseUrl}/auth/phone/${phoneNumber}`;
  const response = await fetch(url);

  if (response.status === 204) {
    return true;
  } else if (response.status === 404) {
    const error = new Error('getPhoneExisting: phone not found');
    error.httpCode = 'auth/phone/401';
    throw error;
  } else {
    const error = new Error('getPhoneExisting: request failed @@');
    error.httpCode = response.status;
    throw error;
  }
};

export const submitRegister = async (data) => {
  const url = `${baseUrl}/registration`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 204) {
    return true;
  } else if (response.status === 404) {
    const error = new Error('getPhoneExisting: phone not found');
    error.httpCode = 'registration/company-code/404';
    throw error;
  } else {
    const error = new Error('getPhoneExisting: request failed @@');
    error.httpCode = response.status;
    throw error;
  }
};
