import { useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import Advice from '../components/Advice';
import ClinicalDiagnosis from '../components/clinical-diagnosis/ClinicalDiagnosis';
import BackgroundError from '../components/error/BackgroundError';
import BackgroundMessage from '../components/error/BackgroundMessage';
import Inclusion from '../components/Inclusion';
import Layout from '../components/layout/Layout';
import PhysicalStrength from '../components/PhysicalStrength';
import ReportHeader from '../components/ReportHeader';
import SubclinicalDiagnosis from '../components/subclinical-diagnosis/SubclinicalDiagnosis';
import Title from '../components/ui/Title';
import UserProfile from '../components/UserProfile';
import useHttp from '../hooks/use-http';
import { getHealthReport } from '../libs/api';
import AuthContext from '../store/auth-context';
import classes from './HealthExaminationPage.module.css';

const HealthExamination = (props) => {
  const { sendRequest, status, data, error } = useHttp(getHealthReport, true);
  const { authType, token } = useContext(AuthContext);
  const router = useRouteMatch();

  const { resultId } = router.params;

  useEffect(() => {
    sendRequest({ authType, token, resultId });
  }, [sendRequest, authType, token, resultId]);

  let content = <BackgroundMessage inlineMessage="Đang xử lý..." />;

  if (status === 'completed' && data) {
    content = (
      <div className={classes.phe}>
        <ReportHeader />
        <Title>PHIẾU BÁO CÁO KẾT QUẢ CÁ NHÂN</Title>
        <section className={classes.main}>
          <UserProfile value={data.userProfile} />
          <PhysicalStrength value={data.physicalStrength} />
          <ClinicalDiagnosis value={data.clinicalDiagnosis} />
          <SubclinicalDiagnosis value={data.subclinicalDiagnosis} />
          <Inclusion value={data.overallHealthClass} />
          <Advice value={data.adviceFromDoctor} />
        </section>
      </div>
    );
  }

  if (status === 'completed' && error) {
    content = <BackgroundError error={error} />;
  }

  return <Layout>{content}</Layout>;
};

export default HealthExamination;
