import classes from './Inclusion.module.css';

const Inclusion = (props) => {
  return (
    <section>
      <h3 className={classes.title}>IV. Kết luận</h3>
      <div>Hiện đạt sức khoẻ loại: {props.value}</div>
    </section>
  );
};
export default Inclusion;
